import { version } from './version';

export const environment = {
  BASE_URL: 'https://dteitu.giss.com.br/PlatformaDTe',
  production: false,
  product: 'dte',
  client: 'eicon',
  i18n: 'pt_br',
  version: version,
  i18nPath: './assets/i18n/',
  assetsPath: './assets/',
  powerbi: '3523909',
  schema: 'pbi_dte_itu',
  groupId: '8523e400-3bd8-4963-94a5-0ce53b812f3a',
  analiseGeral: '1926b502-c94e-492d-b6ee-85ba5959b687',
  analiseUsuarios: 'ef6d1312-1676-4cec-9f3d-cc4acfd3bcc9',
  analiseComunicados: 'ecc254b9-9c86-4aad-8e4f-20a1cd3b0097',
  analiseProcurador: '4787407b-1a95-4314-b346-dde7c05261f3',
  analiseEventos: 'c38a2f93-f2ae-4dfe-83cb-62d824731e3f'
};
